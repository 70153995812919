import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { HiMenu, HiOutlineX } from "react-icons/hi";
import { IoIosArrowForward } from "react-icons/io";
import { VscArrowRight } from "react-icons/vsc";
import LightLogo from "../../assets/img/home/logo_white.svg";
import whatsapp from "../../assets/img/home/HomePageIcons/WhatsaIcon.png";
const OffCanvasMenu = dynamic(() => import("./OffCanvasMenu"), { ssr: false });
import {
  RideHailling,
  Commerce,
  navHomeOne,
  Logistics,
  Services,
  Resources,
  solutionLinks,
} from "../../utils/data";
import dynamic from "next/dynamic";
import { useDispatch } from "react-redux";
import { addTaskToList } from "src/redux/slice/counterSlice";
const Navbar = ({ navDark, insurance, classOption, OverViewNav }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const dispatch = useDispatch();
  return (
    <header
      className={`main-header  ${
        navDark || OverViewNav ? "position-absolute" : ""
      } w-100 ${classOption} ${insurance && "ins-header  z-100"} 
      `}
    >
      <main>
        <nav
          className={`navbar navbar-expand-xl ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${
            OverViewNav ? "bg_gradient_header" : "bg-gradient"
          }   ${scroll > headerTop ? "affix" : ""}`}
        >
          <section className="container d-flex align-items-center justify-content-lg-between position-relative px-lg-4   navHeight">
            <section>
              <Link href="/" passHref legacyBehavior>
                <a className="navbar-brand d-flex align-items-center  py-1  mb-0 text-decoration-none">
                  {scroll > headerTop || !navDark ? (
                    <Image
                      width={133}
                      height={52}
                      src={LightLogo.src}
                      alt="ZervX all-in-one mobility platform "
                      className="img-fluid logo-color "
                      loading="lazy"
                    />
                  ) : (
                    <Image
                      width={133}
                      height={52}
                      src={LightLogo.src}
                      alt="ZervX all-in-one mobility platform"
                      className="img-fluid logo-white"
                      loading="lazy"
                    />
                  )}
                </a>
              </Link>
            </section>
            <section className="mobileContact btn-group navbar-toggler position-absolute top-0 end-0 border-0 left-10 ">
              <section className="action-btns px-2 getStartedMobile">
                <Link
                  href="https://wa.me/918667213477?text=Hello"
                  target="_blank"
                >
                  <a
                    className="btn btn-outline-light text-light px-2 py-1 msw d-flex align-items-center"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ gap: 5, fontSize: "13px" }}
                  >
                    <Image
                      width={12}
                      height={12}
                      src={whatsapp}
                      alt="ZervX all-in-one mobility platform"
                      className="img-fluid logo-color text-start"
                      aria-label="Close"
                      loading="lazy"
                    />
                    Get in Touch
                  </a>
                </Link>
              </section>
              <section className="action-btns  d-block d-md-none d-lg-none">
                <Link href="/industries/contact-sales" passHref>
                  <a
                    className="btn text-light p-1 px-2 py-1 msv btn-outline-light"
                    style={{ fontSize: "13px", paddingTop: "100px" }}
                  >
                    Start Free Trial
                  </a>
                </Link>
              </section>
            </section>

            <button
              className=" text-white navbar-toggler position-absolute right-0 border-0 mx-0 "
              id="#offcanvasWithBackdrop"
              role="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBackdrop"
              aria-controls="offcanvasWithBackdrop"
              aria-label="File menu"
              aria-labelledby="Filemenu"
            >
              <span className="text-white">
                <HiMenu
                  className="menu"
                  style={{ fontSize: 25, marginTop: 6, marginRight: -8 }}
                />
              </span>
            </button>
            <div className="clearfix"></div>
            <section className="collapse navbar-collapse justify-content-end px-4 ">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu ">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle "
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Platform
                  </a>
                  <article className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white ">
                    <article
                      className="dropdown-grid rounded-custom  shadowNav"
                      id="widthPlatform"
                    >
                      <div className="dropdown-grid-item ">
                        <p className="drop-heading fw-bold">
                          <span className="bg-color-gray px-2 py-1 rounded-3 fw-bolder  ">
                            Platform
                          </span>
                        </p>
                        {navHomeOne.map((navH, i) => (
                          <span key={i + 1} className="pl-2 ">
                            <Link href={navH.href} passHref legacyBehavior>
                              <a
                                className="dropdown-link  displayIcon ms-2 flex align-items-start  "
                                onClick={() => {
                                  navH.title === "Tools" &&
                                    dispatch(addTaskToList(navH.title));
                                }}
                              >
                                <Image
                                  src={navH.icon.src}
                                  alt={navH.altText}
                                  width={100}
                                  height={100}
                                  className=" opacity "
                                  loading="lazy"
                                />
                                <span className="dropdown-info mb-0 fw-bold pb-2 ms-3">
                                  <span className="drop-title fw-bold  fs-6 text-dark ">
                                    {navH.title}
                                    <IoIosArrowForward className="mx-1 hide text-dark " />
                                  </span>
                                  <p className="opacity">{navH.info}</p>
                                </span>
                              </a>
                            </Link>
                          </span>
                        ))}
                      </div>
                    </article>
                  </article>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Industries
                  </a>
                  <article className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white ">
                    <article className="dropdown-grid rounded-custom_Inside  width-full shadowNav">
                      <div className="dropdown-grid-item">
                        <p className="drop-heading fw-bold">
                          <span className="bg-color-gray px-2 py-1 rounded-3 fw-bolder">
                            Ride Hailing
                          </span>
                        </p>
                        {RideHailling.map((navH, i) => (
                          <span key={i + 1}>
                            <Link href={navH.href} passHref>
                              <a className="dropdown-link">
                                <img
                                  src={navH.icon.src}
                                  alt={navH.imgalt}
                                  width={27}
                                  height={27}
                                  className="ps-2 img-fluid opacity mr-3 marginRight"
                                  loading="lazy"
                                />
                                <span className="dropdown-info mb-0">
                                  <span className="drop-title  ">
                                    {navH.title}
                                  </span>
                                </span>
                              </a>
                            </Link>
                          </span>
                        ))}
                      </div>
                      <div className="dropdown-grid-item radius-right-side bg-white">
                        <p className="drop-heading fw-bold">
                          <span className="bg-color-gray px-2 py-1 rounded-3 fw-bolder">
                            Commerce
                          </span>
                        </p>
                        {Commerce.map((navH, i) => (
                          <span key={i + 10}>
                            <Link href={navH.href} passHref>
                              <a className="dropdown-link">
                                <img
                                  src={navH.icon.src}
                                  alt={navH.imgalt}
                                  width={27}
                                  height={27}
                                  className="ps-2 img-fluid opacity marginRight"
                                  loading="lazy"
                                />
                                <span className="dropdown-info mb-0">
                                  <span className="drop-title">
                                    {navH.title}
                                  </span>
                                </span>
                              </a>
                            </Link>
                          </span>
                        ))}
                      </div>
                      <div className="dropdown-grid-item radius-right-side bg-white">
                        <p className="drop-heading fw-bold">
                          <span className="bg-color-gray px-2 py-1 rounded-3 fw-bolder">
                            Logistics
                          </span>
                        </p>
                        {Logistics.map((navH, i) => (
                          <span key={i + 10}>
                            <Link href={navH.href} passHref>
                              <a className="dropdown-link">
                                <img
                                  src={navH.icon.src}
                                  alt={navH.imgalt}
                                  width={27}
                                  height={27}
                                  className="ps-2 img-fluid  opacity marginRight"
                                  loading="lazy"
                                />
                                <span className="dropdown-info mb-0">
                                  <span className="drop-title">
                                    {navH.title}
                                  </span>
                                </span>
                              </a>
                            </Link>
                          </span>
                        ))}
                      </div>
                      <div className="dropdown-grid-item radius-right-side bg-white">
                        <p className="drop-heading fw-bold">
                          <span className="bg-color-gray px-2 py-1 rounded-3 fw-bolder">
                            Services
                          </span>
                        </p>
                        {Services.map((navH, i) => (
                          <span key={i + 1}>
                            <Link href={navH.href} passHref>
                              <a className="dropdown-link">
                                <img
                                  src={navH.icon.src}
                                  alt={navH.imgalt}
                                  width={27}
                                  height={27}
                                  className="ps-2 img-fluid opacity marginRight"
                                  loading="lazy"
                                />
                                <span className="dropdown-info mb-0">
                                  <span className="drop-title">
                                    {navH.title}
                                  </span>
                                </span>
                              </a>
                            </Link>
                          </span>
                        ))}
                      </div>
                    </article>
                    <article className="content-between px-3 p-3 fw-bold ">
                      <Link href="/industries/overview" passHref>
                        <a>
                          <div className="d-flex">
                            <h6 className=" text-gray px-3 mb-0 fontSize ">
                              See industries overview page
                            </h6>
                            <VscArrowRight
                              className=" fs-5 text-green w-20"
                              style={{ marginTop: 0.9 }}
                            />
                          </div>
                        </a>
                      </Link>
                      <Link href="/industries/contact-sales" passHref>
                        <a>
                          <h6 className="text-black me-4 mb-0 fontSize">
                            Contact sales
                          </h6>
                        </a>
                      </Link>
                    </article>
                  </article>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Solutions
                  </a>
                  <article className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <article
                      className="rounded-custom shadowNav"
                      style={{ width: "425px" }}
                    >
                      <div className="dropdown-grid-item ev-count-down-three">
                        {solutionLinks.map((navH, i) => (
                           <span key={i + 1}>
                             <Link href={navH.href} passHref>
                              <a className="dropdown-link">
                                <img
                                  src={navH.icon.src}
                                  alt={navH.imgalt}
                                  width={28}
                                  height={28}
                                  className="ps-2 img-fluid opacity marginRight"
                                  loading="lazy"
                                />
                                <span className="dropdown-info mb-0">
                                  <span className="drop-title  "
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "block",
                                    width: "100%",
                                  }}
                                  >
                                    {navH.title}
                                  </span>
                                </span>
                              </a>
                            </Link>
                         </span>
                        ))}
                      </div>
                    </article>
                  </article>
                </li>

                <li>
                  <Link href="/plans" passHref>
                    <a className="nav-link">Pricing</a>
                  </Link>
                </li>
                <li>
                  <Link href="/blog/articles" passHref>
                    <a
                      className="nav-link"
                      onClick={() => {
                        router.push("/blog/articles");
                      }}
                    >
                      Blogs
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/about-us" passHref>
                    <a className="nav-link">About Us</a>
                  </Link>
                </li>
              </ul>
            </section>
            <section className="action-btns action-btns-lg ">
              <Link href="/industries/contact-sales" passHref>
                <a className="btn btn-outline-light text-light  p-1 px-4  ">
                  Start Free Trial
                </a>
              </Link>
            </section>
            <section className="action-btns px-4 getStarted">
              <Link
                href="https://wa.me/918667213477?text=Hello"
                target="_blank"
              >
                <a
                  className=" btn btn-outline-light text-light p-1 px-4 btn-width px-md-4 d-flex align-items-center  "
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ gap: 8 }}
                >
                  <Image
                    width={22}
                    height={22}
                    src={whatsapp}
                    alt="ZervX all-in-one mobility platform"
                    className="img-fluid logo-color text-start"
                    aria-label="Close"
                    loading="lazy"
                  />
                  Get in Touch
                </a>
              </Link>
            </section>
            {/* <section className="action-btns action-btns-small-lg  me-0 me-lg-0 px-2 d-block d-md-none d-lg-none  " style={{marginRight: -25,fontSize: 5}}>
                <Link href="/industries/contact-sales" passHref>
                  <a className="btn  text-light p-1 px-1  bg-gradient_btn" style={{marginRight: 10,fontSize: 12.16,borderRadius:6}}>
                    Start Free Trial
                  </a>
              </Link>
            </section> */}

            <section
              className="offcanvas offcanvas-end d-xl-none bg-gradient"
              tabIndex="-1"
              id="offcanvasWithBackdrop"
            >
              <section className="offcanvas-header d-flex align-items-center mt-4">
                <article>
                  <Link href="/" passHref>
                    <a className="d-flex align-items-start mb-md-0 text-decoration-none">
                      <Image
                        width={110}
                        height={60}
                        src={LightLogo.src}
                        alt="ZervX all-in-one mobility platform"
                        className="img-fluid logo-color text-start"
                        aria-label="Close"
                        loading="lazy"
                      />
                    </a>
                  </Link>
                </article>
                <button
                  type="button"
                  className="close-btn text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <HiOutlineX />
                </button>
              </section>
              <OffCanvasMenu />
            </section>
          </section>
        </nav>
      </main>
    </header>
  );
};
export default Navbar;
